.especialidades{
    display: flex;
    align-items: center;
    max-width: 50%;
    flex-wrap: wrap;
    gap: 40px;
    margin-top: 50px;
  }
.card{
  justify-content: center;
  align-items: center;
  display: flex;
  height: 200px;
  width: 200px;
  background-color: var(--azul);
  flex: 1 0 200px;
  color: var(--bege);
  border-radius: 40px;
  font-size: 25px;
  box-shadow: 0px 20px 20px rgba(0,0,0, 0.2);
  transition: transform 500ms;
  text-align: center;
}
.card:hover, .card:focus{
  transform: scale(1.2);
  transition: 0.5s ease-in-out;
}