.banner{
  display: flex;
  justify-content: space-around;
  align-items: center;
  position: fixed;
  top: 0;
  background-color: #047884;
  width: 100%;
  height: 100px;
  box-shadow: 0px 15px 20px rgba(0,0,0, 0.2);
}
.banner img{
  max-width: 200px;
  max-height: auto;
  margin-top: 10px;
}
ul{
  display: flex;
  justify-content: center;
  align-items: center;
  text-decoration: none;
  list-style: none;
  gap: 30px;
}
ul a{
  display:flex;
  justify-content: center;
  align-items: center;
  text-decoration: none;
  max-width: 100%;
  color: #fceccb;
  font-weight: 700;
  transition: transform 500ms;
}
ul a:hover, ul a:focus, ul a:active, ul a.touchstart{
  transform: scale(1.05);
  color: #ef7c53;
  transition: 0.5s ease-in;
}