.About{
  display: flex;
  justify-content: space-around;
  align-items: center;
  max-width: 950px;
  height: 385px;
  margin-top: 150px;
  background-color: var(--vermelho);
  gap: 30px;
  border-radius: 30px;
  color: var(--bege);
  box-shadow: 0px 15px 20px rgba(0, 0, 0, 0.25);
}
.LeftDiv{
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 60%;
  height: 100%;
  margin-left: 20px;
  text-align: center;
  gap: 10px;
}
.LeftDiv h2{
  display: flex;
  font-weight: 400;
  font-size: 35px;
}
.RightDiv{
  display: flex;
  justify-content: center;
  align-items: center;
  width: 30%;
  height: 100%;
  padding: 10px;
  background-color: var(--pastel);
  border-radius: 0px 30px 30px 0px;
  box-shadow: 0px 15px 20px rgba(0, 0, 0, 0.25);
}
.RightDiv img{
  display: flex;
  max-width: auto;
  height: 100%;
  border-radius: 0px 30px 30px 0px;
  box-shadow: 0px 15px 20px rgba(0, 0, 0, 0.25);
}