.Estrutura{
  display: flex;
  justify-content: center;
  align-items: center;
  width:100%;
  height: 700px;
  background-color: var(--pastel);
  flex-direction: column;
  color: var(--bege);
  margin-top: 100px;
}
.leftEstrutura{
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  flex-direction: column;
  width: 100%;
  height: 100%;
  
}
.leftEstrutura h3{
  display: flex;
  font-weight: 400;
  font-size: 40px;
}
.leftEstrutura p{
  display: flex;
  max-width: 600px;
}
.rightEstrutura{
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  width: 100%;
  height: 100%;
  cursor: grab;
  overflow: hidden;
}
.inner{
  display: flex;
}
.item{
  height: 400px;
  width: 300px;
  padding: 5px;
  
}
.item img{
  width: 100%;
  height: 90%;
  border-radius: 12px;
  pointer-events: none;
}
.carousel{
  cursor: grab;
  
}
