.Unidade{
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 100%;
  height: 700px;
  background-color: var(--vermelho);
  margin-top: 100px;
  color: var(--bege);
}
.ContactUnidade{
  display:flex ;
  justify-content: center;
  align-items: center;
  text-align: center;
  flex-direction: column;
  margin-top: 20px;
}
.ContactUnidade h3{
  display: flex;
  font-size: 25px;
}
a{
  cursor: pointer;
}
.ContactUnidade button {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 0px;
  background: var(--azul);
  border-radius: 10px 90px 30px 50px;
  box-shadow: 6px 13px 21px  rgba(0, 0, 0, 0.349);
  overflow: hidden;
  border: none;
  margin-top: 10px;
  cursor: pointer;
  transition: transform 500ms ;
 }
 
.ContactUnidade button:after {
  content: " ";
  width: 0%;
  height: 100%;
  background: var(--pastel);
  position: absolute;
  transition: all 0.4s ease-in-out;
  right: 0;
 }
.ContactUnidade button:hover,.ContactUnidade button:focus{
  transform: scale(1.1);
 }
.ContactUnidade button:hover::after {
  right: auto;
  left: 0;
  width: 100%;
 }
 
.ContactUnidade button span {
  text-align: center;
  text-decoration: none;
  width: 100%;
  padding: 18px 25px;
  color: var(--bege);
  font-size: 1.125em;
  font-weight: 700;
  letter-spacing: 0.3em;
  z-index: 20;
  transition: all 0.3s ease-in-out;
 }
 
.ContactUnidade button:hover span {
  color: #fff;
  animation: scaleUp 0.3s ease-in-out;
 }
 
 @keyframes scaleUp {
  0% {
   transform: scale(1);
  }
 
  50% {
   transform: scale(0.95);
  }
 
  100% {
   transform: scale(1);
  }
 }
 

 
.AboutUnidade{
  display: flex;
  justify-content:space-around;
  align-items: center;
  gap: 50px;
  margin-top: 50px;
}
.LeftUnidade{
  display: flex;
  justify-content: center;
  align-items: flex-start;
  text-align: left;
  flex-direction: column;
  width: 50%;
  height: 100%;
}
.LeftUnidade h3{
  display: flex;
  font-weight: 400;
  font-size: 40px;
}
.LeftUnidade p{
  display: inline-block;
  max-width: 600px;
}
.RigthUnidade{    
  display: flex;
  justify-content: center;
  align-items: center;
  width: 50%;
  height: 100%;
}
.RigthUnidade iframe{
  display: flex;
  width: 900px;
  height: 400px;
  border-radius: 30px;
  box-shadow: 6px 33px 81px -20px rgba(0,0,0,0.75);
}